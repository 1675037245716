import useDictionary from '../../utils/useDictionary'
import { AmalfiServiceStatus } from '@amalfi-analytics/components'
import useData from '../../utils/useData'
import type {
  IrisServiceStatusType,
  IrisServiceStatusData
} from '../../types/componentTypes'

const IrisServiceStatus = ({
  element
}: {
  element: IrisServiceStatusType
}): JSX.Element => {
  const { props, dictName, applicationID } = element
  const { data, loading, error } = useData<IrisServiceStatusData>(element)
  const { searchInDictionary } = useDictionary(dictName, applicationID)
  if (loading || error !== undefined || data === undefined) {
    return <></>
  }
  const { isOnline, lastUpdate } = data
  return (
    <AmalfiServiceStatus
      status={{
        isOnline,
        onlineLabel: searchInDictionary(props?.onlineLabel),
        offlineLabel: searchInDictionary(props?.offlineLabel)
      }}
      lastUpdate={
        lastUpdate // eslint-disable-line
          ? {
              date: new Date(lastUpdate),
              dateFormat: props?.dateFormat,
              label: searchInDictionary(props?.lastUpdateLabel)
            }
          : undefined
      }
    />
  )
}

export default IrisServiceStatus
